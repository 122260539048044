import React from 'react';
import './JobPost.css'; // Ensure you have this CSS file for styles
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const sendEmail = () => {
  window.location.href = "mailto:support@avvikelser.com";
};



const JobPost = () => {
  return (


    <div className="job-background">
    <header className="app-header">
        <div className="header-content">

          <Link to="/">
            <img src={`${process.env.PUBLIC_URL}/assets/retailx.png`} alt="Retail X Logo" className="header-logo" />
          </Link>
                <button className="contact-button" onClick={sendEmail}>Contact Us</button>
        </div>
    </header>
      <div className="job-card">
        <h3>Join Our Innovative Team!</h3>
        <br />
       
        <p>RetailX is a dynamic and fast-growing company specializing in helping influencers create unique products for their communities. We focus on product design, development, supply chains, and all aspects needed to transform an idea into a final product. As we continue to expand, we're seeking a <strong>Sales Operations Manager</strong> to optimize our sales processes and contribute to our ambitious global objectives. This role is ideal for someone with a strong background in sales operations, a strategic approach, and outstanding organizational skills.</p>
<br />
        
        <h4>Key Responsibilities:</h4>

<br />

        <ul>
          <li>Sales Process Optimization: Collaborate with the sales team to refine and enhance sales processes.</li>
          <li>Active Sales Engagement: Direct involvement in sales activities, including initiating dialogues with potential influencer clients, closing deals, and maintaining key customer relationships.</li>
          <li>Data Analysis & Strategic Planning: Analyze sales data to identify improvement areas and develop strategic plans.</li>
          <li>Sales Reporting & Insights: Generate comprehensive sales reports and dashboards to guide the leadership team.</li>
          <li>CRM Management: Supervise and improve the CRM system for accurate and current data maintenance.</li>
          <li>Team Training & Support: Educate and assist the sales team with tools, processes, and best practices.</li>
        </ul>
<br />

        <h4>Qualifications:</h4>
<br />

        <ul>
          <li>Previous experience as SDR / BDR / Account Executive or equivalent experience.</li>
          <li>Proven experience in B2B sales operations.</li>
          <li>Strong analytical and problem-solving skills.</li>
          <li>Excellent communication and interpersonal abilities.</li>
          <li>Proficiency in sales tools and being tech-savvy.</li>
          <li>Experience in SaaS or technology sectors, is advantageous.</li>
          <li>Adaptable, proactive, and comfortable in a fast-paced startup environment.</li>
        </ul>

<br />
        <h4>Benefits:</h4>
<br />

        <p>Initially commission-based salary with equity package option. Flexible working hours. Social gatherings, skill development opportunities, and office perks like coffee and tea. A unique opportunity to work in a thriving startup environment, directly contributing to the company's growth and success.</p>
<br />
      
        <p><strong>Apply by sending an email.</strong></p>
      </div>
    </div>
  );
}

export default JobPost;
